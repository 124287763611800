<template>
  <div class="admin-list">
    <div class="table-header text-black bg-white rounded shadow px-6 py-4 mt-1 flex items-center">
      <div class="flex-one item-color w-2/5 mr-4">
        <div class="flex items-center">
          <div
            :style="bagroundDefine"
            class="float-left w-8 h-8 text-center pt-2 -mt-1 mr-2 rounded-full first-letter-section text-sm font-bold text-white"
          >
            {{ getFirstLetter(name) }}
          </div>
          <div class="name">
            {{ userList.firstName + ' ' + userList.lastName }}
          </div>
        </div>
      </div>
      <div class="flex-one item-color w-1/5">
        <div v-if="userList.phone === null">-</div>
        <div v-else>{{ userList.phone }}</div>
      </div>
      <div class="flex-one item-color w-2/5 mr-4">
        {{ truncateString(userList.email, 25) }}
      </div>
      <div class="flex-one item-color w-2/12">
        <div class="right flex items-center">
<!--          <div class="icon cursor-pointer">
            <svg-icon
              name="ic_more-info"
              original
              class="w-4 h-4"
            />
          </div>-->
          <div class="number capitalize">{{ userList.role[0] }}</div>
<!--          <div class="lock">
            <svg-icon
              name="ic_fe_lock"
              original
              class="w-4 h-4"
            />
          </div>-->
        </div>
      </div>
      <div class="flex-one item-color w-1/12">
        <div class="icones flex items-center justify-center">
<!--          <div class="update mr-4 cursor-pointer">
            <svg-icon
              name="ic_update"
              original
              class="w-5 h-5"
            />
          </div>-->
          <div class="update cursor-pointer" @click="isConfirm=true">
            <svg-icon
              name="ic_feather-trash"
              original
              class="w-5 h-5"
            />
          </div>
        </div>
      </div>
    </div>
    <success-add
      v-if="successAdd"
      :message="$t('deleteAdminSuccess')"
      :button-label="$t('goBackAddMemberButton')"
      @closeSuccess="closeSuccess"
    />

    <confirm-delete-admin
      v-if="isConfirm"
      :admin-id="userList.id"
      @closeDelete="closeDelete"
      @openSuccessDelete="openSuccessDelete"
    />
  </div>
</template>

<script>
import http from '../../../plugins/http'
import apiRoutes from '../../../router/api-routes'
import SuccessAdd from '../success-add'
import ConfirmDeleteAdmin from '@/components/helper/admin/confirm-delete-admin.vue'
export default {
  name: 'admin-list',
  components: { ConfirmDeleteAdmin, SuccessAdd },
  props: {
    userList: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      successAdd: false,
      name: this.userList.firstName + ' ' + this.userList.lastName,
      bagroundDefine: {
        backgroundColor: '#000000'
      },
      isConfirm: false
    }
  },
  created () {
    const colors = ['#01C8C8', '#FFA83D', '#6D1FEB', '#2F80ED', '#0DCE89', '#7D47B3', '#1ECC78', '#C1CD18', '#581845', '#000080']
    const value = Math.floor(Math.random() * (colors.length + 1))
    if (value < colors.length) {
      this.bagroundDefine.backgroundColor = colors[value]
    } else {
      this.bagroundDefine.backgroundColor = colors[0]
    }
  },
  methods: {
    openSuccessDelete (value) {
      this.isConfirm = false
      this.successAdd = value
    },
    closeDelete (value) {
      this.isConfirm = value
    },
    closeSuccess () {
      this.successAdd = false
      window.location.reload()
    },
    deleteAdmin () {
      http.delete(apiRoutes.baseURL + apiRoutes.deleteAdmin, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        },
        data: {
          userId: this.userList.userId
        }
      }).then(response => {
        console.log(response)
        this.successAdd = true
      }).catch(error => {
        console.log(error)
      })
    },
    getFirstLetter (fullname) {
      const nameTable = fullname.split(' ')
      if (nameTable.length >= 2) {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[1].charAt(0).toUpperCase()
      } else {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[0].charAt(1).toUpperCase()
      }
    },
    truncateString (str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .table-header:hover {
    background: rgba(1,101,200,0.1);
    border-left: 2px solid $pass_marron;
    box-shadow: -6px 0px 6px 0px rgba(51, 50, 50, 0.45);
  }
  .svg-fill {
    fill: transparent;
  }
</style>
