<template>
  <div class="admin">
    <div
      v-if="!addAdmin"
      class="begin"
    >
      <div class="header">
        <div class="flex items-center justify-between">
          <div class="titles">
            <div class="title text-black font-semibold text-lg">
              {{ $t('adminMenu').substr(-20, 15) }}
            </div>
          </div>
          <div class="flex-one current-month ">
            <div class="search-bloc flex items-center justify-between">
              <div class="recherche-name">
                <div class="relative recherche justify-center items-center pl-8 pr-4 py-4 bg-white shadow rounded">
                  <div class="icon-search absolute inset-y-0 left-0 flex items-center px-4">
                    <svg-icon
                      name="ic_search"
                      original
                      class="icon w-4 h-4"
                    />
                  </div>
                  <div class="search-input ml-4">
                    <label>
                      <input
                        v-model="search"
                        type="text"
                        :placeholder="$t('searchName')"
                        class="recherche-input"
                      >
                    </label>
                  </div>
                </div>
              </div>
              <div class="select-pays ml-4">
                <button-base
                  :label="$t('addMemberButton')"
                  @click.native="showAddAdmin"
                />
              </div>
            </div>
          </div>
        </div><br>
      </div>
      <div class="body mt-10">
        <div class="user-list bg-white rounded font-semibold shadow p-6 flex items-center">
          <div class="flex-one item-color w-2/5">{{ $t('completeName') }}</div>
          <div class="flex-one item-color w-1/5">{{ $t('phone') }}</div>
          <div class="flex-one item-color w-2/5">{{ $t('email') }}</div>
          <div class="flex-one item-color w-2/12 uppercase">{{ $t('AccessRights') }}</div>
          <div class="flex-one item-color w-1/12 uppercase">{{ $t('actions') }}</div>
        </div>
        <div class="loading" :class="{ 'not_empty': isLoading === false }">
          <loading
            :active="isLoading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            loader="dots"
            color="#0B5382"
            background-color="transparent"
          />
        </div>

        <div v-if="nothing && !isLoading" class="no-student text-center text-lg py-32">
          {{ $t('noAdminAdded') }}
        </div>
        <div v-if="!isLoading" class="list-user">
          <admin-list
            v-for="(item, i) in sortedArrayUser"
            :key="i"
            :user-list="item"
          />

          <pagination-base
            :currentPage="currentPage"
            :totalPages="totalPages"
            :visiblePages="visiblePages"
            @page-change="handlePageChange"
          />
        </div><br>
      </div>
    </div>
    <add-admin
      v-if="addAdmin"
      @clickToGoBack="clickToGoBack"
    />
  </div>
</template>

<script>
import ButtonBase from '../../components/helper/add/button-base'
import AddAdmin from '../../components/helper/admin/add-admin'
import http from '@/plugins/http'
import apiRoute from '@/router/api-routes'
import AdminList from '@/components/helper/admin/admin-list'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import PaginationBase from '@/components/helper/add/pagination-base.vue'
export default {
  name: 'index',
  components: { PaginationBase, AdminList, AddAdmin, ButtonBase, Loading },
  props: {
    isSearch: String
  },
  data () {
    return {
      search: '',
      addAdmin: false,
      isLoading: true,
      fullPage: false,
      nothing: false,
      admin: [],
      currentPage: 1,
      totalPages: 0,
      visiblePages: 0,
      limit: 10
    }
  },
  created () {
    this.getAllAdmin(this.currentPage, this.limit)
  },
  computed: {
    sortedArrayUser: function () {
      let sorted = []
      for (let i = 0; i < this.admin.length; i++) {
        sorted.push(this.admin[i])
      }
      if (this.search !== '') {
        let authorNameSearchString = this.search
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if ((item.firstName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() + ' ' + item.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
            (item.phone !== null && (item.phone.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) ||
            (item.email.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
            // console.log(item)
            return item
          }
        })
      }
      return sorted
    }
  },
  methods: {
    handlePageChange (newPage) {
      // Mettez à jour la page actuelle ici (ex: effectuez une requête pour charger la nouvelle page de données)
      this.currentPage = newPage
      this.getAllAdmin(newPage, this.limit)
    },
    getAllAdmin (page, limit) { // GET ALL ADMIN
      http.post(apiRoute.baseURL + apiRoute.getAdmin, {
        page: page,
        limit: limit
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.admin = response.rows
        this.currentPage = page
        this.totalPages = Math.ceil(response.count / limit)
        // console.log(this.totalPages);
        const visiblePage = 1
        this.visiblePages = Math.min(visiblePage, this.totalPages)

        if (this.admin.length === 0) {
          this.isLoading = false
          this.nothing = true
        } else {
          this.isLoading = false
          this.nothing = false
        }
      }).catch(error => {
        console.log(error)
      })
    },
    clickToGoBack () { // CLOSE ADD NEW ADMIN
      this.addAdmin = false
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    showAddAdmin () { // OPEN ADD ADMIN
      this.addAdmin = true
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .admin {
    padding: 2rem 4rem 4rem 1.5rem;
  }
  .user-list {
    color: $light_gray;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
</style>
