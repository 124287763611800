<template>
  <div class="add-admin">
    <div class="begin pb-12">
      <div class="back cursor-pointer">
        <svg-icon
          name="ic_go-back"
          original
          class="w-8"
          @click="goBack"
        />
      </div><br>
      <div class="form bg-white rounded-lg p-8 m-auto w-1/2">
        <div class="title text-black text-xl font-medium">
          {{ $t('newMember') }}
        </div><br>
        <div class="login-form">
          <div class="label mb-2">{{ $t('name') }}</div>
          <div class="input">
            <label>
              <input
                v-model="name"
                type="text"
                class="ipt border rounded-lg w-full p-4"
              >
            </label>
          </div>
        </div><br>
        <div class="login-form">
          <div class="label mb-2">{{ $t('surname') }}</div>
          <div class="input">
            <label>
              <input
                v-model="surname"
                type="text"
                class="ipt border rounded-lg w-full p-4"
              >
            </label>
          </div>
        </div><br>
        <div class="login-form">
          <div class="label mb-2">{{ $t('email') }}</div>
          <div class="input">
            <label>
              <input
                v-model="email"
                type="email"
                class="ipt border rounded-lg w-full p-4"
              >
            </label>
          </div>
        </div><br>
        <div class="login-form">
          <div class="label mb-2">{{ $t('phone') }}</div>
          <div class="input">
            <label>
              <input
                v-model="phone"
                type="tel"
                maxlength="8"
                class="ipt border rounded-lg w-full p-4"
                @keypress="isNumberOnly"
                @input="getNumber"
              >
            </label>
          </div>
        </div><br>
        <div class="login-form">
          <div class="label mb-2">{{ $t('role') }}</div>
          <div class="input w-full">
            <div class="relative">
              <label>
                <select
                  v-model="role"
                  class="ipt appearance-none w-full border py-4 pl-4 pr-8 rounded-lg"
                >
                  <option value="ADMIN">Administrateur</option>
                  <option value="SUPERVISOR">Superviseur</option>
                  <option value="VISITOR">Visiteur</option>
                </select>
              </label>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
                <svg-icon
                  name="ic_dropdown"
                  original
                  class="w-4 h-4"
                />
              </div>
            </div>
          </div>
        </div><br>
        <div
          v-if="error"
          class="text-red-500 text-center mt-2"
        >
          {{ errorAdmin }}
        </div>
      </div><br>
      <div class="login-button m-auto w-1/2 mb-24">
        <button-base
          :label="$t('addButton')"
          :is-loading="loader"
          @click.native="addNewMember"
        />
      </div>
    </div>
    <success-add
      v-if="successAdd"
      :message="$t('newAddMemberSuccess')"
      :button-label="$t('goBackAddMemberButton')"
      @closeSuccess="closeSuccess"
    />
  </div>
</template>

<script>
import ButtonBase from '../add/button-base'
import SuccessAdd from '../success-add'
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
export default {
  name: 'add-admin',
  components: { SuccessAdd, ButtonBase },
  data () {
    return {
      name: '',
      surname: '',
      phone: '',
      role: '',
      email: '',
      error: false,
      errorAdmin: '',
      loader: false,
      successAdd: false
    }
  },
  methods: {
    getNumber () {
      console.log(this.phone)
      console.log(this.phone.slice(-8))
    },
    closeSuccess () {
      this.successAdd = false
      window.location.reload()
    },
    addNewMember () {
      if (this.name === '' || this.surname === '' || this.phone === '' || this.role === '' || !this.validEmail(this.email)) {
        this.error = true
        this.errorAdmin = this.$t('errorAddAdmin')
      } else {
        this.error = false
        this.loader = true
        const right = []
        right.push(this.role)
        http.post(apiRoute.baseURL + apiRoute.createAdmin, {
          email: this.email,
          firstName: this.surname,
          lastName: this.name,
          phone: this.phone,
          role: right
        }, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.loginToken}`
          }
        }).then(response => {
          console.log(response)
          this.successAdd = true
        }).catch(error => {
          console.log(error)
          this.loader = false
          if (error.data.statusCode === 409) {
            this.error = true
            this.errorAdmin = this.$t('errorAdminExist')
          }
        })
      }
    },
    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      // alert(keyCode)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    },
    validEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    goBack () {
      this.$emit('clickToGoBack', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .ipt {
    background-color: $light_gray3;
    color: black;
  }
  .svg-fill {
    fill: transparent;
  }
  .login-button::v-deep {
    .button-base {
      .page-button-real {
        width: 100%;
        height: 3.5rem;
      }
    }
  }
</style>
